<template>
    <div>
        <div v-if="!mqttinfo" style="padding: 60px 0 40px 0; text-align: center">
            <p>设备MQTT信息未添加，是否立即初始化</p>
            <div>
                <el-button size="small" type="primary" @click="onInitMqtt()">初始化MQTT</el-button>
            </div>
        </div>
        <el-form v-else ref="refFormDeviceMqtt" class="geology-form" :rules="formRules" :model="mqttinfo"
            label-width="100px" style="padding-top: 20px">
            <el-row>
                <el-col :span="10" :offset="1">
                    <el-form-item label="设备ID" prop="ClientId">
                        <el-input placeholder="请输入设备ID" v-model="mqttinfo.ClientId" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="帐号" prop="UserName">
                        <el-input placeholder="请输入帐号" v-model="mqttinfo.UserName" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="授权秘钥" prop="AuthKey">
                        <el-input placeholder="请输入授权秘钥" v-model="mqttinfo.AuthKey" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23" :offset="1">
                    <el-form-item>
                        <el-button type="primary" size="medium" @click="onFormSubmit">立即保存</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { getDeviceMqtt, initDeviceMqtt, editDeviceMqtt } from "@/api/device";
export default {
    data() {
        return {
            mqttinfo: "",
            formRules: {
                AuthKey: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入授权秘钥",
                    },
                ],
            },
        };
    },
    props: {
        deviceid: {
            type: [String, Number],
            default: 0,
        },
    },
    methods: {
        getDeviceMqtt() {
            if (this.deviceid) {
                getDeviceMqtt(this.deviceid).then((res) => {
                    if (res.code == 0 && res.data) {
                        this.mqttinfo = res.data;
                    }
                });
            }
        },
        onInitMqtt() {
            initDeviceMqtt(this.deviceid)
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("初始化成功");
                        this.getDeviceMqtt();
                    } else {
                        this.$message.error("初始化失败!" + res.msg);
                    }
                })
                .catch((errmsg) => {
                    this.$message.error("初始化失败!" + errmsg);
                });
        },
        onFormSubmit() {
            editDeviceMqtt(this.mqttinfo)
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("保存成功");
                    } else {
                        this.$message.error("保存失败!" + res.msg);
                    }
                })
                .catch((errmsg) => {
                    this.$message.error("保存失败!" + errmsg);
                });
        },
    },
    created() {
        this.getDeviceMqtt();
    },
    watch: {
        deviceid() {
            if (this.deviceid > 0) {
                this.getDeviceMqtt();
            }
        },
    },
};
</script>

<style></style>