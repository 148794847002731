<template>
  <div class="danger-form-page" v-loading="pageload">
    <el-form ref="form" class="geology-form" :rules="formRules" :model="form" label-width="120px">
      <el-tabs class="geology-tabs" v-model="activeTabName" style="margin-bottom: 20px">
        <el-tab-pane label="基本信息" name="tabBase">
          <el-divider content-position="left">基本信息</el-divider>
          <el-row>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点" prop="DangerId">
                <el-select style="width: 100%" v-model="form.DangerId" filterable remote reserve-keyword
                  default-first-option placeholder="请选择、搜索监测点" size="medium" :remote-method="getDangerList"
                  :loading="getDangerLoading" @change="onDangerChange">
                  <el-option v-for="item in dangerSelect" :key="item.Id" :label="item.DangerName" :value="item.Id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点位" prop="MonitorId">
                <el-select style="width: 100%" v-model="form.MonitorId" filterable remote reserve-keyword
                  default-first-option placeholder="请选择监测点位" size="medium" :remote-method="getMonitorList"
                  :loading="getMonitorLoading">
                  <el-option v-for="item in monitorSelect" :key="item.Id" :label="item.MonitorName" :value="item.Id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>


            <el-col :span="9" :offset="1">
              <el-form-item label="设备名称" prop="DeviceName">
                <el-input v-model="form.DeviceName" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备编号" prop="DeviceCode">
                <el-input v-model="form.DeviceCode" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备IEMI" prop="DeviceIemi">
                <el-input v-model="form.DeviceIemi" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备类型" prop="DeviceType">
                <el-select v-model="form.DeviceType" size="small" placeholder="请选择" style="width: 100%">
                  <el-option v-for="item in DeviceTypeData" :key="item.Id" :label="item.ItemName"
                    :value="item.ItemValue">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="固件版本" prop="SoftVer">
                <el-input v-model="form.SoftVer" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="物联网卡" prop="MobileCard">
                <el-input v-model="form.MobileCard" size="small"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="19" :offset="1">
              <el-form-item label="监测地址" prop="Address">
                <el-input v-model="form.Address" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="19" :offset="1">
              <el-form-item label="经纬度">
                <el-col :span="7">
                  <el-form-item label-width="0px">
                    <el-input v-model="form.Longitude" size="small" placeholder="请输入经度，例：116.393116933"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7" :offset="1">
                  <el-form-item label-width="0px">
                    <el-input v-model="form.Latitude" size="small" placeholder="请输入纬度，例：39.90734137"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" :offset="1">
                  <el-form-item label="排序">
                    <el-input v-model="form.SortCode" size="small" placeholder="请输入排序"></el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-col>

            <el-col :span="9" :offset="1">
              <el-form-item label="是否预警" prop="IsWaring">
                <el-switch v-model="form.IsWaring">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="预警等级" prop="WaringLevel">
                <el-select style="margin-left: 10px" v-model="form.WaringLevel" placeholder="请选择预警等级">
                  <el-option label="未预警" :value="0"></el-option>
                  <el-option label="蓝色" :value="1"></el-option>
                  <el-option label="黄色" :value="2"></el-option>
                  <el-option label="橙色" :value="3"></el-option>
                  <el-option label="红色" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备状态" prop="IsOn">
                <el-select style="margin-left: 10px" v-model="form.IsOn" placeholder="请选择设备状态">
                  <el-option label="离线" :value="0"></el-option>
                  <el-option label="在线" :value="1"></el-option>
                  <el-option label="维修" :value="2"></el-option>
                  <el-option label="拆除" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="19" :offset="1">
              <el-form-item label="设备图片" prop="Images">
                <multi-file ref="inspectMulitFile" text="选择设备图片" :auto-upload="true" :show-file-list="true"
                  :file-list="formImageList" :limit="5" list-type="picture-card" @complete="uploadComplete" />
                <div>最多允许上传5张图片</div>
              </el-form-item>
            </el-col>

            <el-col :span="23" :offset="1">
              <el-form-item>
                <el-button type="primary" size="medium" @click="onSubmit">立即保存</el-button>
                <el-button type="primary" plain icon="el-icon-circle-close" size="medium" style="margin-left: 10px"
                  @click="$router.go(-1)">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="初始值信息" name="tabImage">
          <form-initvalue v-model="initValues" :formtype="formtype" :detailid="form.Id"></form-initvalue>
        </el-tab-pane>
        <el-tab-pane label="预警信息接收人" v-if="formtype == 'edit'" name="tabWarnpush">
          <warn-push :deviceInfo="form"></warn-push>
        </el-tab-pane>
        <el-tab-pane label="预警设置" name="tabFile">
          <form-warning v-model="warings" :formtype="formtype" :detailid="form.Id"></form-warning>
        </el-tab-pane>
        <el-tab-pane label="测斜深度" v-if="formtype == 'edit' && form.DeviceType == '测斜仪'" name="tabInclinometer">
          <form-inclinometer :detailid="form.Id"></form-inclinometer>
        </el-tab-pane>
        <el-tab-pane label="MQTT" v-if="formtype == 'edit'" name="tabMqtt">
          <form-mqtt :deviceid="form.Id"></form-mqtt>
        </el-tab-pane>

      </el-tabs>
    </el-form>
  </div>
</template>
<script>

import {
  getDangerList,
  getMonitorList,
} from "@/api/base";
import {
  getDeviceDetail,
  addDevice,
  editDevice,
} from "@/api/device";
import formInitvalue from "./components/formInitvalue";
import formWarning from "./components/formWarning";
import formInclinometer from "./components/formInclinometer";
import warnPush from "./components/warnPush";
import formMqtt from "./components/formMqtt";
import { MultiFile } from "@/components/upload";
export default {
  data() {
    return {
      pageload: false,
      activeTabName: "tabBase",

      //监测点
      getDangerLoading: false,
      dangerSelect: [],

      //监测点位
      getMonitorLoading: false,
      monitorSelect: [],


      DeviceTypeData: [],

      formImageList: [],
      form: {
        DangerId: "",
        MonitorId: "",
        DeviceName: "",
        DeviceCode: "",
        DeviceIemi: "",
        DeviceType: "",
        Longitude: "",
        Latitude: "",
        Address: "",
        SoftVer: "",
        MobileCard: "",
        IsWaring: true,
        Id: 0,
        SortCode: "",
        IsOn: "",
      },
      formRules: {
        DangerId: [
          { required: true, trigger: "blur", message: "请选择监测点" },
        ],
        MonitorId: [
          { required: true, trigger: "blur", message: "请选择监测点位" },
        ],
        DeviceName: [
          { required: true, trigger: "blur", message: "请输入设备名称" },
        ],
        DeviceCode: [
          { required: true, trigger: "blur", message: "请输入设备编号" },
        ],
        DeviceIemi: [
          { required: true, trigger: "blur", message: "请输入设备IEMI" },
        ],
        DeviceType: [
          { required: true, trigger: "blur", message: "请选择设备类型" },
        ],
      },

      initValues: [],
      warings: [],
    };
  },
  components: {
    formInitvalue,
    formWarning,
    formInclinometer,
    warnPush,
    formMqtt,
    MultiFile,
  },
  props: ["formtype"],
  methods: {

    getDangerList(value) {
      this.getDangerLoading = true;
      getDangerList(1, 100, {
        fname: value,
      }).then((resdata) => {
        this.getDangerLoading = false;
        if (resdata.code == 0) {
          this.dangerSelect = resdata.data.data;
        }
      });
    },
    onDangerChange(value) {
      this.getMonitorList();
      this.form.MonitorId = '';
    },
    getMonitorList(value) {
      this.getMonitorLoading = true;
      getMonitorList(1, 100, {
        fname: value,
        dangerid: this.form.DangerId,
      }).then((resdata) => {
        this.getMonitorLoading = false;
        if (resdata.code == 0) {
          this.monitorSelect = resdata.data.data;
        }
      });
    },

    uploadComplete(files) {
      this.form.Images = files.map(item => item.path).join(',');
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addDevice({
              deviceModel: this.form,
              initValues: this.initValues,
              warings: this.warings,
            })
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 500);
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editDevice(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 500);
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {

    this.getDangerList("");

    this.$store.dispatch("dictionary/getDeviceType").then((data) => {
      this.DeviceTypeData = data;
    });

    if (this.formtype == "edit") {
      this.pageload = true;
      var detailId = this.$route.query.id;
      getDeviceDetail(detailId)
        .then((res) => {
          this.pageload = false;
          if (res.code == 0) {
            this.form = res.data[0];
            if (this.form.Images) {
              this.formImageList = this.form.Images.split(',').map(item => {
                return {
                  url: item
                };
              })
            }
          }
        })
        .catch((errmsg) => {
          this.pageload = true;
          this.$message.error(errmsg);
        });
    }
  },
};
</script>